@font-face {font-family: "Druk Wide Web Bold";
  src: url("./assets/fonts/fonts/druk-wide-web-bold/29800a9e7d146302b8ed9ad2f848db63.eot"); /* IE9*/
  src: url("./assets/fonts/fonts/druk-wide-web-bold/29800a9e7d146302b8ed9ad2f848db63.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./assets/fonts/fonts/druk-wide-web-bold/29800a9e7d146302b8ed9ad2f848db63.woff2") format("woff2"), /* chrome、firefox */
  url("./assets/fonts/fonts/druk-wide-web-bold/29800a9e7d146302b8ed9ad2f848db63.woff") format("woff"), /* chrome、firefox */
  url("./assets/fonts/fonts/druk-wide-web-bold/29800a9e7d146302b8ed9ad2f848db63.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("./assets/fonts/fonts/druk-wide-web-bold/29800a9e7d146302b8ed9ad2f848db63.svg#Druk Wide Web Bold Regular") format("svg"); /* iOS 4.1- */
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.woff2') format('woff2'),
      url('./assets/fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.woff2') format('woff2'),
      url('./assets/fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-ThinItalic.woff2') format('woff2'),
      url('./assets/fonts/Montserrat-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Black.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-BlackItalic.woff2') format('woff2'),
      url('./assets/fonts/Poppins-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/Overpass-Regular.woff2') format('woff2'),
      url('./assets/fonts/Overpass-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Thin.woff2') format('woff2'),
      url('./assets/fonts/Montserrat-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/Overpass-Italic.woff2') format('woff2'),
      url('./assets/fonts/Overpass-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Poppins-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraLight.woff2') format('woff2'),
      url('./assets/fonts/Poppins-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraBold.woff2') format('woff2'),
      url('./assets/fonts/Poppins-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Italic.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraBoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Poppins-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Bold.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraLightItalic.woff2') format('woff2'),
      url('./assets/fonts/Poppins-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/Poppins-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Medium.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
      url('./assets/fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Light.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-MediumItalic.woff2') format('woff2'),
      url('./assets/fonts/Poppins-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-BlackItalic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Black.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Thin.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ThinItalic.woff2') format('woff2'),
      url('./assets/fonts/Poppins-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Poppins-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Light.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Italic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Roboto-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #010003;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: #fff;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
}
#root {
  overflow-x: hidden;
}
img {
  width: 100%;
}
div {
  box-sizing: border-box;
}
p {
  margin: 0;
  padding: 0;

}
h1, h2,h3,h4,h5,h5 {
  margin: 0;
}

button {
  border: 0;
}

a,
button {
  text-decoration: none;
  color: #fff;
  font-family: 'Roboto';
  display: block;
}
a.btn--solid,
button.btn--solid {
  background: #1D0AE0;
  padding: 20px 60px;
  border-radius: 50px;
  box-sizing: border-box;
}
a.btn--outlined,
button.btn--outlined {
  background: transparent;
  border: solid 1px #fff;
  padding: 20px 60px;
  box-sizing: border-box;
  border-radius: 50px;
}

.separator {
  border: 2px solid #FFFFFF;
  width: 98px;
  margin-top: 15px;
}
.coming-soon {
    cursor: pointer;
}

.language {
  width: 50px;
  display: flex;
  position: relative;
  align-items: center;
}

.language--desktop {
  margin-left: 20px;
}

.language-select {
  display: flex;
  cursor: pointer;
  position: relative;
}

.language-select > div {
  width: 30px;
  margin-right: 10px;
}

.language-select:after {
  content: '';
  height: 7px;
  width: 8px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  display: inline-block;
  position:relative;
}

.language > div + div {
  position: absolute;
  width: 150px;
  display: block;
  left: -80%;
  top: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.language__item + .language__item {
  display: flex;
}

.language__item svg {
  width: 30px;
  margin-right: 10px;
  border: 1px solid #ddd;
}

.language__item {
  font-family: 'Poppins';
  color: #000;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.language__item {
  padding: 10px;
}

.language__item:hover {
  background-color: rgba(0,0,0,0.1);
}

.language--mobile {
  display: none;
  align-self: center;
}

.language select {
  font-family: 'Poppins';
  background-color: transparent;
  color: #fff;
  border-radius: 5px;
  padding: 2px 7px;
  font-size: 18px;
  border: 2px solid #fff;
}

.language option {
  color: #000;
}

.blur-circle {
  position: absolute;
  z-index: -1;
  border-radius: 100%; 
  opacity: 1;
  filter: blur(86.3095px);
  animation: bounce 7s ease-in-out infinite;
  animation-delay: 4s;
}
.blur-circle.dark-blue{
  background: radial-gradient(circle at 50% 50%,rgba(29, 10, 224, 0.22), rgba(29, 10, 224, 0.78));
}
.blur-circle.blue{
  background: radial-gradient(circle at 50% 50%,rgba(15, 8, 209, 0.2), rgba(15, 8, 209, 0.8));
}
.blur-circle.light-blue{
  background: radial-gradient(circle at 50% 50%,rgba(29, 10, 224, 0.2), rgba(29, 10, 224, 0.8));
}
.blur-circle.grey{
  background: radial-gradient(circle at 50% 50%,rgba(82, 89, 94, 0.3), rgba(82, 89, 94, 0.7));
}
/* Changed from red to green color */
.blur-circle.green{
  background: radial-gradient(circle at 50% 50%,rgb(0, 194, 124, 0.2), rgba(0, 194, 124, 0.8));
}
/* Changed to dark red to blue */
.blur-circle.sky-blue{
  background: radial-gradient(circle at 50% 50%,rgba(0, 135, 211, 0.2), rgba(0, 135, 211, 0.8));
}
/* Changed from light red to purple */
.blur-circle.purple{
  background: radial-gradient(circle at 50% 50%, rgba(127, 17, 224, 0.2), rgba(127, 17, 224, 0.8));
}
.blur-circle.pink{
  background: radial-gradient(circle at 50% 50%,rgba(201, 0, 108, 0.2), rgba(201, 0, 108, 0.8));
}


@keyframes float {
	0% {
		/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
		transform: translatey(0px);
	}
	50% {
		/* box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2); */
		transform: translatey(-20px);
	}
	100% {
		/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
		transform: translatey(0px);
	}
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
@keyframes bounce {
    0% {
        transform: translate(0px, 0px) scale(1);
    }
    33% {
        transform: translate(40px, -100px) scale(0.9);
    }
    66% {
        transform: translate(-50px, 30px) scale(0.5);
    }
    100% {  
        transform: translate(0px, 0px) scale(1);
    }
}
@keyframes bounce-grey {
  0% {
      transform: translate(0px, 0px) scale(1);
  }
  33% {
      transform: translate(40px, -150px) scale(1.1);
  }
  66% {
      transform: translate(-100px, -150px) scale(0.5);
  }
  100% {  
      transform: translate(0px, 0px) scale(1);
  }
}
@keyframes bounce-green {
  0% {
      transform: translate(0px, 0px) scale(1);
  }
  33% {
      transform: translate(-40px, -150px) scale(1.1);
  }
  66% {
      transform: translate(100px, 150px) scale(0.5);
  }
  100% {  
      transform: translate(0px, 0px) scale(1);
  }
}
@keyframes bounce-purple {
  0% {
      transform: translate(0px, 0px) scale(1);
  }
  33% {
      transform: translate(-40px, 150px) scale(1.1);
  }
  66% {
      transform: translate(-100px, 150px) scale(0.5);
  }
  100% {  
      transform: translate(0px, 0px) scale(1);
  }
}
@keyframes bounce-sky-blue {
  0% {
      transform: translate(0px, 0px) scale(1);
  }
  33% {
      transform: translate(80px, 150px) scale(1.1);
  }
  66% {
      transform: translate(100px, -150px) scale(0.5);
  }
  100% {  
      transform: translate(0px, 0px) scale(1);
  }
}
.bouncing {
    animation: bounce 4s ease-in-out infinite;
    animation-delay: 4s;
}
.bobbing {
    animation: float 6s ease-in-out infinite;
    overflow: hidden;
}
.spinning {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}
.bounce-grey {
  animation: bounce-grey 3s ease-in-out infinite;
  animation-delay: 4s;
}
.bounce-green {
  animation: bounce-green 4s ease-in-out infinite;
  animation-delay: 4s;
}
.bounce-purple {
  animation: bounce-purple 5s ease-in-out infinite;
  animation-delay: 4s;
}
.bounce-sky-blue {
  animation: bounce-sky-blue 3.5s ease-in-out infinite;
  animation-delay: 4s;
}

@media all and (max-width: 1023px) {
  .language--desktop {
    display: none;
  }

  .language--mobile {
    display: block;
    text-align: center;
    margin-top: 20px;
  }
  
  .language select {
    font-size: 16px;
  }
}