.footer {
    width: 100%;
    background-image: url('../../assets/images/header-bg.png');
    background-position: center center;
    background-size: cover;
    position: relative;
    background-color: rgba(6, 5, 7, 0.5);
    /* mix-blend-mode: darken; */
    background-blend-mode: darken;
    background-repeat: no-repeat;
    padding: 122px 0 56px 0;
    box-sizing: border-box;
    overflow: hidden;
}
.footer__wrapper {
    max-width: 1300px;
    width: 95%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
}
.footer__image {
    position: absolute;
    top: 32%;
    right: -146px;
    max-width: 680px;
    width: 55%;
    transform: translateY(-50%);
}
.footer__container {
    position: relative;
    border-radius: 30px;
    width: 100%;
    padding: 92px 62px 80px 62px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 100px;
    background: rgba(0, 0, 0, 0.4);
    mix-blend-mode: normal;
    backdrop-filter: blur(6px);
}
.footer__container::before {
    border-radius: 30px;
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    padding: 2px;
    background:linear-gradient(182deg,#1D0AE1,#F7061C); 
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events:none
}
.footer__socials {
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer__socials ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}
.footer__socials ul li {
    margin: 0 27.5px;
}
.footer__socials ul li:first-of-type {
    margin-left: 0;
}
.footer__logo {
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer__bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer__logo img {
    max-width: 58px;
}
.footer__logo  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 20px;
    letter-spacing: 0.107433px;
}
.footer__title h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 35px;
    margin-bottom: 10px;
}
.footer__sub-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 45px;
}
.footer__copyright span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    opacity: 0.8;
}


@media all and (max-width: 1024px) {
    .footer {
        padding: 80px 0 40px 0;
    }

    .footer__image {
        width: 50%;
    }
}

@media all and (max-width: 1023px) {
    .footer__container {
        align-items: center;
    }

    .footer__image {
        display: none;
    }

    .footer__socials {
        width: 100%;
    }

    .footer__title {
        text-align: center;
    }

    .footer__socials ul {
        justify-content: space-between;
        width: 100%;
    }

    .footer__socials ul li {
        margin: 0;
    }
}

@media all and (max-width: 767px) {
    .footer__container {
        padding: 50px 40px;
        margin-bottom: 80px;
    }

    .footer__title h3 {
        font-size: 30px;
        line-height: 1.5;
    }

    .footer__sub-title {
        text-align: center;
        line-height: 1.5;
        margin-bottom: 30px;
    }

    .footer__socials ul li {
        margin: 0 5px;
    }

    .footer__bottom {
        flex-direction: column;
    }

    .footer__logo {
        margin-bottom: 10px;
    }
}