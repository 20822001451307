
.popup {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 59;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s ease 0.5s, opacity 0.5s ease;
    -webkit-transition: visibility 0s ease 0.5s, opacity 0.5s ease;
}

.popup.active {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s ease, opacity 0.5s ease;
    -webkit-transition: visibility 0s ease, opacity 0.5s ease;
}

.popup__click {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.popup__wrapper {
    background: #FFFFFF;
    border-radius: 20px;
    max-width: 1255px;
    width: 95%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 70px 50px;
    position: relative;
    z-index: 2;
}

.popup__logo {
    width: 30%;
}

.popup__logo img {
    max-width: 369px;
}
.popup__socials {
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup__socials ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}
.popup__socials ul li {
    margin: 0 15.5px;
}
.popup__title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.popup__title span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 90px;
    text-align: center;
    color: #000000;
}
.popup__title span:last-of-type {
    margin-bottom: 31px;
}
.popup__title p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #474747;
    margin-bottom: 31px;
}

@media all and (max-width: 1300px) {
    .popup__title span {
        font-size: 55px;
        line-height: 1.5;
    }
    
    .popup__title span:last-of-type {
        margin-bottom: 20px;
    }
}

@media all and (max-width: 1024px) {
    .popup__socials {
        width: 100%;
    }

    .popup__socials ul {
        width: 30%;
        justify-content: center;
    }
}

@media all and (max-width: 1023px) {

    .popup__socials ul {
        width: auto;
    }

}

@media all and (max-width: 767px) {

    .popup__logo {
        width: 100%;
    }
    
    .popup__title span {
        font-size: 35px;
    }

    .popup__title p {
        font-size: 25px;
        line-height: 1.5;
    }

    .popup__socials ul {
        width: 80%;
    }

    .popup__wrapper {
        width: 90%;
        padding: 50px 10%;
    }
}