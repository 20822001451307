
.navigation {
    width: 100%;
    position: fixed;
    z-index: 10;
}

.navigation > div {
    margin: 0 auto;
    padding: 20px 5%;
}

.header__nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1920px;
    padding: 36px 5%;
}

.header__nav.active {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
}

.header__logo {
    display: flex;
    align-items: center;
    justify-content: center;
}
.header__logo img{
    max-width: 58px;
}

.header__logo span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: 0.107433px;
    position: relative;
    display: block;
    background-color: transparent;
}

.header__menu {
    display: flex;
}

.header__menubox {
    display: flex;
}

.header__menu ul {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    margin: 0;
}
.header__menu ul li {
    margin: 0 20px;
}
.header__menu ul li:last-of-type {
    margin-right: 0;
}
.header__menu a {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.11px;
    text-decoration: none;
    color: #fff;
}

.header__hamburger {
    display: none;
}

.hamburger {
    width: 40px;
    height: 25px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.hamburger:before,
.hamburger:after {
    content: '';
    width: 100%;
    height: 2px;
    left: 0;
    background-color: #fff;
    position: absolute;
}

.hamburger span {
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: opacity 0.5s ease;
    -webkit-transition: opacity 0.5s ease;
}

.hamburger:before {
    top: 0;
    transition: top 0.2s ease 0.3s, transform 0.3s ease;
    -webkit-transition: top 0.2s ease 0.3s, transform 0.3s ease;
}

.hamburger:after {
    bottom: 0;
    transition: bottom 0.2s ease 0.3s, transform 0.3s ease;
    -webkit-transition: bottom 0.2s ease 0.3s, transform 0.3s ease;
}

.hamburger.active span {
    opacity: 0;
}

.hamburger.active:before {
    top: calc(50% - 1px);
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: top 0.2s ease, transform 0.3s ease 0.2s;
    -webkit-transition: top 0.2s ease, transform 0.3s ease 0.2s;
}

.hamburger.active:after {
    bottom: calc(50% - 1px);
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transition: bottom 0.2s ease, transform 0.3s ease 0.2s;
    -webkit-transition: bottom 0.2s ease, transform 0.3s ease 0.2s;
}

@media all and (min-width: 1440px) {
    .navigation > div {
        padding: 36px 5%;
    }
}

@media all and (max-width: 1024px) {
    .header__logo span {
        font-size: 28px;
    }
    
    .header__menubox ul {
        padding-left: 0;
    }
}

@media all and (max-width: 1023px) {

    .header__hamburger {
        display: block;
        z-index: 10;
    }

    .header__menu {
        width: 100%;
        position: fixed;
        top: 0;
        right: -100%;
        background-color: transparent;
        height: 100vh;
        transition: right 0.3s ease 0.2s, background-color 0.2s ease;
        -webkit-transition: right 0.3s ease 0.2s, background-color 0.2s ease;
    }

    .header__menu.active {
        right: 0;
        background-color: rgba(0,0,0,0.4);
        transition: right 0.3s ease, background-color 0.2s ease 0.3s;
        -webkit-transition: right 0.3s ease, background-color 0.2s ease 0.3s;
    }

    .header__menu > div {
        width: 50%;
        background-color: #000;
        margin: 0 auto;
        padding: 0 5%;
        margin-right: 0;
        border-left: 1px solid #fff;
    }

    .header__menubox {
        flex-direction: column;
    }
    
    .header__menu ul {
        margin: 0 auto;
        padding: 0;
        padding-top: 90px;
        width: 100%;
        flex-direction: column;
        position: relative;
        justify-content: flex-start;
    }

    .header__menu a {
        font-size: 24px;
    }

    .header__menu ul li {
        margin: 0;
        padding: 0 20px;
        margin-bottom: 25px;
        width: 100%;
    }

    .header__menu li:last-child a {
        text-align: center;
    }
}

@media all and (max-width: 767px) {

    .header__menu > div {
        width: 75%;
    }
}

@media all and (max-width: 400px) {

    .header__menu > div {
        width: 100%;
        border-left: 0;
    }
}