.header {
    position: relative;
    width: 100%;
    /* background-image: url('../images/BG-TOP.jpg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain; */
}
.header__wrapper {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding-top: 163px;
    position: relative;
    background-image: url('../../assets/images/BG-TOP.jpg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
}

.header__image-1 {
    width: 30%;
    height: auto;
    /* position: absolute; */
    /* top: 100px; */
    /* left: 0; */
    z-index: 1;
}
.header__image-2 {
    width: 30%;
    height: auto;
    /* height: 811px; */
    /* position: absolute; */
    /* top: 127px; */
    /* right: 0; */
    z-index: 1;
}
.header__image-3 {
    width: 279px;
    height: 280px;
    position: absolute;
    top: 45%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
}
.header__top-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    width: 100%;
}
.header__title {
    margin-top: 55px;
    z-index: 2;
    position: relative;
}
.header__title h1 {
    font-family: 'Overpass';
    font-size: 70px;
    font-weight: 700;
    line-height: 70px;
    text-align: center;
    margin-bottom: 23px;
    position: relative;
    display: block;
    background-color: transparent;
}
    
.header__title .before-span, .header__title .after-span,
.header__title .before-span-2, .header__title .after-span-2,
.header__title .before-span-3, .header__title .after-span-3 {
    color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    overflow: hidden;
    top: 0;
    font-family: 'Overpass';
    font-size: 70px;
    font-weight: 700;
    line-height: 70px;
    text-align: center;
    margin-bottom: 23px;
}

.header__title .before-span {
    left: 3px;
    text-shadow: -5px 0 red;
    animation-name: glitch-animation-1;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

.header__title .after-span {
    left: -3px;
    text-shadow: -5px 0 blue;
    animation-name: glitch-animation-2;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

.header__title .before-span-2 {
    left: 3px;
    text-shadow: -5px 0 red;
    animation-name: glitch-animation-3;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

.header__title .after-span-2 {
    left: -3px;
    text-shadow: -5px 0 blue;
    animation-name: glitch-animation-4;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

.header__title .before-span-3 {
    left: 3px;
    text-shadow: -5px 0 red;
    animation-name: glitch-animation-5;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

.header__title .after-span-3 {
    left: -3px;
    text-shadow: -5px 0 blue;
    animation-name: glitch-animation-6;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

@keyframes glitch-animation-1 {
    0% {
      clip: rect(51px, 1000px, 32px, 0); }
    5% {
      clip: rect(62px, 1000px, 62px, 0); }
    10% {
      clip: rect(27px, 1000px, 36px, 0); }
    15% {
      clip: rect(46px, 1000px, 45px, 0); }
    20% {
      clip: rect(9px, 1000px, 50px, 0); }
    25% {
      clip: rect(6px, 1000px, 68px, 0); }
    30% {
      clip: rect(67px, 1000px, 2px, 0); }
    35% {
      clip: rect(32px, 1000px, 24px, 0); }
    40% {
      clip: rect(48px, 1000px, 59px, 0); }
    45% {
      clip: rect(66px, 1000px, 31px, 0); }
    50% {
      clip: rect(14px, 1000px, 65px, 0); }
    55% {
      clip: rect(29px, 1000px, 16px, 0); }
    60% {
      clip: rect(37px, 1000px, 1px, 0); }
    65% {
      clip: rect(53px, 1000px, 55px, 0); }
    70% {
      clip: rect(52px, 1000px, 50px, 0); }
    75% {
      clip: rect(55px, 1000px, 19px, 0); }
    80% {
      clip: rect(51px, 1000px, 40px, 0); }
    85% {
      clip: rect(31px, 1000px, 58px, 0); }
    90% {
      clip: rect(3px, 1000px, 68px, 0); }
    95% {
      clip: rect(6px, 1000px, 9px, 0); }
    100% {
      clip: rect(36px, 1000px, 28px, 0); }
}
  
  @keyframes glitch-animation-2 {
    0% {
      clip: rect(48px, 1000px, 21px, 0); }
    5% {
      clip: rect(46px, 1000px, 64px, 0); }
    10% {
      clip: rect(56px, 1000px, 1px, 0); }
    15% {
      clip: rect(50px, 1000px, 40px, 0); }
    20% {
      clip: rect(13px, 1000px, 53px, 0); }
    25% {
      clip: rect(57px, 1000px, 16px, 0); }
    30% {
      clip: rect(19px, 1000px, 31px, 0); }
    35% {
      clip: rect(46px, 1000px, 31px, 0); }
    40% {
      clip: rect(41px, 1000px, 12px, 0); }
    45% {
      clip: rect(48px, 1000px, 2px, 0); }
    50% {
      clip: rect(19px, 1000px, 46px, 0); }
    55% {
      clip: rect(37px, 1000px, 18px, 0); }
    60% {
      clip: rect(49px, 1000px, 32px, 0); }
    65% {
      clip: rect(56px, 1000px, 57px, 0); }
    70% {
      clip: rect(18px, 1000px, 4px, 0); }
    75% {
      clip: rect(48px, 1000px, 56px, 0); }
    80% {
      clip: rect(62px, 1000px, 57px, 0); }
    85% {
      clip: rect(30px, 1000px, 57px, 0); }
    90% {
      clip: rect(47px, 1000px, 13px, 0); }
    95% {
      clip: rect(30px, 1000px, 36px, 0); }
    100% {
      clip: rect(35px, 1000px, 60px, 0); }
}

@keyframes glitch-animation-3 {
    0% {
      clip: rect(121px, 1000px, 102px, 0); }
    5% {
      clip: rect(132px, 1000px, 132px, 0); }
    10% {
      clip: rect(97px, 1000px, 106px, 0); }
    15% {
      clip: rect(116px, 1000px, 115px, 0); }
    20% {
      clip: rect(79px, 1000px, 120px, 0); }
    25% {
      clip: rect(76px, 1000px, 138px, 0); }
    30% {
      clip: rect(137px, 1000px, 72px, 0); }
    35% {
      clip: rect(102px, 1000px, 94px, 0); }
    40% {
      clip: rect(118px, 1000px, 129px, 0); }
    45% {
      clip: rect(136px, 1000px, 101px, 0); }
    50% {
      clip: rect(84px, 1000px, 135px, 0); }
    55% {
      clip: rect(99px, 1000px, 86px, 0); }
    60% {
      clip: rect(107px, 1000px, 71px, 0); }
    65% {
      clip: rect(123px, 1000px, 125px, 0); }
    70% {
      clip: rect(122px, 1000px, 120px, 0); }
    75% {
      clip: rect(125px, 1000px, 89px, 0); }
    80% {
      clip: rect(121px, 1000px, 110px, 0); }
    85% {
      clip: rect(101px, 1000px, 128px, 0); }
    90% {
      clip: rect(73px, 1000px, 138px, 0); }
    95% {
      clip: rect(76px, 1000px, 79px, 0); }
    100% {
      clip: rect(106px, 1000px, 98px, 0); }
}
  
  @keyframes glitch-animation-4 {
    0% {
      clip: rect(118px, 1000px, 81px, 0); }
    5% {
      clip: rect(116px, 1000px, 134px, 0); }
    10% {
      clip: rect(126px, 1000px, 71px, 0); }
    15% {
      clip: rect(120px, 1000px, 110px, 0); }
    20% {
      clip: rect(83px, 1000px, 123px, 0); }
    25% {
      clip: rect(127px, 1000px, 86px, 0); }
    30% {
      clip: rect(89px, 1000px, 101px, 0); }
    35% {
      clip: rect(116px, 1000px, 101px, 0); }
    40% {
      clip: rect(111px, 1000px, 82px, 0); }
    45% {
      clip: rect(118px, 1000px, 72px, 0); }
    50% {
      clip: rect(89px, 1000px, 116px, 0); }
    55% {
      clip: rect(107px, 1000px, 88px, 0); }
    60% {
      clip: rect(119px, 1000px, 102px, 0); }
    65% {
      clip: rect(126px, 1000px, 127px, 0); }
    70% {
      clip: rect(88px, 1000px, 74px, 0); }
    75% {
      clip: rect(118px, 1000px, 126px, 0); }
    80% {
      clip: rect(132px, 1000px, 127px, 0); }
    85% {
      clip: rect(100px, 1000px, 127px, 0); }
    90% {
      clip: rect(117px, 1000px, 83px, 0); }
    95% {
      clip: rect(100px, 1000px, 106px, 0); }
    100% {
      clip: rect(105px, 1000px, 130px, 0); }
}



@keyframes glitch-animation-5 {
    0% {
      clip: rect(191px, 1000px, 172px, 0); }
    5% {
      clip: rect(202px, 1000px, 202px, 0); }
    10% {
      clip: rect(167px, 1000px, 176px, 0); }
    15% {
      clip: rect(186px, 1000px, 185px, 0); }
    20% {
      clip: rect(149px, 1000px, 190px, 0); }
    25% {
      clip: rect(146px, 1000px, 208px, 0); }
    30% {
      clip: rect(207px, 1000px, 142px, 0); }
    35% {
      clip: rect(172px, 1000px, 164px, 0); }
    40% {
      clip: rect(188px, 1000px, 199px, 0); }
    45% {
      clip: rect(206px, 1000px, 171px, 0); }
    50% {
      clip: rect(154px, 1000px, 205px, 0); }
    55% {
      clip: rect(169px, 1000px, 156px, 0); }
    60% {
      clip: rect(177px, 1000px, 141px, 0); }
    65% {
      clip: rect(193px, 1000px, 195px, 0); }
    70% {
      clip: rect(192px, 1000px, 190px, 0); }
    75% {
      clip: rect(195px, 1000px, 159px, 0); }
    80% {
      clip: rect(191px, 1000px, 180px, 0); }
    85% {
      clip: rect(171px, 1000px, 198px, 0); }
    90% {
      clip: rect(143px, 1000px, 208px, 0); }
    95% {
      clip: rect(146px, 1000px, 149px, 0); }
    100% {
      clip: rect(176px, 1000px, 168px, 0); }
}
  
  @keyframes glitch-animation-6 {
    0% {
      clip: rect(188px, 1000px, 151px, 0); }
    5% {
      clip: rect(186px, 1000px, 204px, 0); }
    10% {
      clip: rect(196px, 1000px, 141px, 0); }
    15% {
      clip: rect(190px, 1000px, 180px, 0); }
    20% {
      clip: rect(153px, 1000px, 193px, 0); }
    25% {
      clip: rect(197px, 1000px, 156px, 0); }
    30% {
      clip: rect(159px, 1000px, 171px, 0); }
    35% {
      clip: rect(186px, 1000px, 171px, 0); }
    40% {
      clip: rect(181px, 1000px, 152px, 0); }
    45% {
      clip: rect(188px, 1000px, 142px, 0); }
    50% {
      clip: rect(159px, 1000px, 186px, 0); }
    55% {
      clip: rect(177px, 1000px, 158px, 0); }
    60% {
      clip: rect(189px, 1000px, 172px, 0); }
    65% {
      clip: rect(196px, 1000px, 197px, 0); }
    70% {
      clip: rect(158px, 1000px, 144px, 0); }
    75% {
      clip: rect(188px, 1000px, 196px, 0); }
    80% {
      clip: rect(202px, 1000px, 197px, 0); }
    85% {
      clip: rect(170px, 1000px, 197px, 0); }
    90% {
      clip: rect(187px, 1000px, 153px, 0); }
    95% {
      clip: rect(170px, 1000px, 176px, 0); }
    100% {
      clip: rect(175px, 1000px, 200px, 0); }
}

.header__video {
    border: solid 1px #fff;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}
.header__video img {
    width: 72px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.header__video .guide {
    width: 100%;   
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0);
    -webkit-transform: translate(0);
}

.header__video a {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.header__links {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 57px;
}
.header__links a,
.header__links button {
    margin: 0 26px;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
}

#features {
    padding-top: 120px;
}

.header__bottom-content {
    max-width: 945px;
    margin: 40px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.header__bottom-content > span {
    font-family: 'Overpass';
    font-weight: 700;
    font-size: 48px;
    line-height: 70px;
    text-align: center;
}
.header__bottom-content > p {
    font-family: 'Overpass';
    font-weight: 300;
    font-size: 26px;
    line-height: 32.92px;
    text-align: center;
    margin-top: 51px;
}
.header__icon-list {
    margin-top: 62px;
}
.header__icon-list > div:first-of-type {
    margin-bottom: 100px;
}
.icon-list__row {
    display: flex;
    align-items: end;
    justify-content: space-between;
    flex-wrap: wrap;
}
.icon-list__item {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 1rem;
}

.icon-list__imgbox {
    height: 60px;
    margin-bottom: 1.25rem;
    display: flex;
    align-items: center;
}

.icon-list__item img {
    transform: scale(0.8);
    width: auto;
    margin: 0 auto;
}
.icon-list__item span {
    font-family: 'Roboto';
    font-size: 18.02px;
    font-weight: 500;
    line-height: 21.11px;
    text-transform: uppercase;
}
.photo-content {
    margin-top: 130px;
}
.photo-content__wrapper {
    max-width: 1300px;
    width: 95%;
    margin: 0 auto;
}
.photo-content__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 76px;
    position: relative;
}
.photo-content__item .blur-circle.grey {
    width: 300px;
    height: 300px;
    right: 312px;
    top: 0;
}
.photo-content__item .blur-circle.purple {
    width: 333px;
    height: 333px;
    right: -30px;
    bottom: -200px;
}
.photo-content__item .blur-circle.green {
    width: 333px;
    height: 333px;
    right: 524px;
    bottom: -200px;
}
.photo-content__item .blur-circle.light-blue {
    width: 333px;
    height: 333px;
    left: -200px;
    top: 50%;
    /* transform: translateY(-50%); */
}
.photo-content__item .blur-circle.purple.last-item {
    width: 333px;
    height: 333px;
    right: -30px;
    bottom: -200px;
}
.photo-content__item .blur-circle.green.last-item {
    width: 333px;
    height: 333px;
    left: -110px;
    bottom: -275px;
}
.photo-content__item--reverse {
    flex-direction: row-reverse;
}
.photo-content__content {
    max-width: 578px;
    width: 50%;
}

.photo-content__image {
    width: 50%;
    position: relative;
}
.photo-content__image .controller--front {
    position: absolute;
    right: -20px;
    top: 129px;
    width: 538px;
    animation-delay: 1s;
    z-index: 2;
}
.photo-content__image .controller--back {
    position: absolute;
    left: -35px;
    top: -10px;
    width: 538px;
    animation-delay: 2s;
    z-index: 1;
}

.photo-content__image--fix-height {
  min-height: 500px;
}

.photo-content__glitch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(10px);
  -webkit-transform: translateX(10px);
}

@keyframes glitchAnim1 {
  0%, 25.1%, 100% {
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
  4% {
    clip-path: polygon(0 0, 100% 0, 100% 10%, 0 10%);
  }
  5% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  9% {
    clip-path: polygon(0 20%, 100% 20%, 100% 30%, 0 30%);
  }
  10% {
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  14% {
    clip-path: polygon(0 40%, 100% 40%, 100% 50%, 0 50%);
  }
  15% {
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
  }
  19% {
    clip-path: polygon(0 60%, 100% 60%, 100% 70%, 0 70%);
  }
  20% {
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  24% {
    clip-path: polygon(0 80%, 100% 80%, 100% 90%, 0 90%);
  }
  25% {
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
}

.photo-content__glitch {
  animation-name: glitchAnim1;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}
.photo-content__image .first.rectangle {
    position: absolute;
    top: -20px;
    right: -10px;
    width: 46px;
}
.photo-content__image .first.diamond {
    position: absolute;
    width: 54px;
    left: 35px;
    bottom: 45px;
}
.photo-content__image .first.triangle {
    position: absolute;
    width: 19px;
    top: 130px;
    left: -60px;
}
.photo-content__image .rectangle {
    position: absolute;
    top: 0;
    right: 70px;
    width: 46px;
    z-index: 3;
}
.photo-content__image .diamond {
    position: absolute;
    width: 54px;
    left: 110px;
    bottom: 60px;
    z-index: 3;
}
.photo-content__image .triangle {
    position: absolute;
    width: 19px;
    top: 100px;
    left: -50px;
    z-index: 3;
}

.photo-content__title {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 23px;
}
.photo-content__content p {
    font-family: 'Montserrat';
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 33px;
}
.photo-content__content a,
.photo-content__content button {
    max-width: 240px;
    font-size: 14px;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #1700F3;
    color: #A4ADFF;
    text-align: center;
}
.faq {
    margin-top: 63px;
}
.faq__wrapper {
    max-width: 1300px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.faq__top-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.faq__title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    line-height: 69px;
    text-align: center;
    text-transform: uppercase;
}
.faq__bottom-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 115px;
    width: 100%;
}
.faq__image {
    /* max-width: 670px; */
    width: 50%;
    position: relative;
    min-height: 500px;
}

.faq__image .controller--front {
  position: absolute;
  left: -20px;
  top: 0;
  width: 450px;
  animation-delay: 1s;
  z-index: 2;
}
.faq__image .controller--back {
  position: absolute;
  right: 60px;
  top: 135px;
  width: 400px;
  animation-delay: 2s;
  z-index: 1;
}
.faq__image .triangle-img {
  position: absolute;
  top: 20.7%;
  right: 4%;
  width: 8.31%;
  z-index: 3;
}
.faq__image .x-img {
  position: absolute;
  bottom: 5%;
  right: 45%;
  width: 11.847%;
  z-index: 3;
}
.faq__image .blur-circle.sky-blue {
  width: 333px;
  height: 333px;
  right: -85px;
  bottom: 30px;
}
.faq__list {
    max-width: 555px;
    width: 50%;
}
.faq__list ul {
    list-style: none;
    padding-left: 50px;
    box-sizing: border-box;
}
.faq__list ul li {
    position: relative;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 25px;
    line-height: 26px;
    margin-top: 36px;
}

.faq__list ul li:first-child {
    margin-top: 0;
}

.faq__list ul li:before {
    content: '';
    width: 12px;
    height: 12px;
    background: #1B0BD8;
    border-radius: 10px;
    position: absolute;
    left: -24px;
    top: 5px;

}
.faq__button {
    width: 100%;
    max-width: 415px;
    margin: 0 auto;
    margin-top: 80px;
}
.faq__button a {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 35px;
    line-height: 1.2;
    padding: 42px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
}

.introduction {
    margin-top: 96px;
}
.introduction__wrapper {
    max-width: 1300px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.introduction__top-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.introduction__title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    line-height: 69px;
    text-align: center;
    text-transform: uppercase;
}
.introduction__bottom-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
}
.introduction__image {
    max-width: 620px;
    width: 50%;
    position: relative;
}
.introduction__image .blur-circle {
    width: 452px;
    height: 452px;
    bottom: -154px;
    left: -110px;
}
.introduction__list {
    max-width: 585px;
    width: 50%;
}
.introduction__list ul {
    list-style: none;
    padding-left: 50px;
    box-sizing: border-box;
}
.introduction__list ul li {
    position: relative;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 25px;
    line-height: 26px;
    margin-top: 36px;
}

.introduction__list ul li:first-child {
    margin-top: 0;
}

.introduction__list ul li:before {
    content: '';
    width: 12px;
    height: 12px;
    background: #1B0BD8;
    border-radius: 10px;
    position: absolute;
    left: -24px;
    top: 5px;

}
.investors {
    margin-bottom: 182px;
    margin-top: 175px;
}
.investors__wrapper {
    max-width: 1300px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.investors__title h2 {
    font-family: Poppins;
    font-weight: 700;
    font-size: 60px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
}
.investors__text {
    margin-top: 137px;
    font-family: Poppins;
    font-style: italic;
    font-weight: 275;
    font-size: 40px;
    line-height: 90px;
    text-align: center;
}
.investors__images {
  display: flex;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
.investors__images a {
  margin: 0 2%;
  width: 25%;
  margin-bottom: 2%;
}

.supporters {
    margin-bottom: 182px;
}
.supporters__wrapper {
    max-width: 1300px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.supporters__circle {
   position: absolute;
   top: 60%;
   right: -20px;
   width: 54px;
   transform: translateY(-50%); 
}
.supporters__title h2 {
    font-family: Poppins;
    font-weight: 700;
    font-size: 60px;
    line-height: 1;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;
}
.supporters__text {
    margin-top: 137px;
    font-family: Poppins;
    font-style: italic;
    font-weight: 275;
    font-size: 40px;
    line-height: 90px;
    text-align: center;
}

.partners {
    margin-bottom: 91px;
}
.partners__wrapper {
    max-width: 1300px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.partners .blur-circle.green {
    width: 333px;
    height: 333px;
    right: -85px;
    bottom: 30px;
}
.partners .blur-circle.pink {
    width: 333px;
    height: 333px;
    left: -85px;
    bottom: 30px;
}
.partners .blur-circle.sky-blue {
    width: 333px;
    height: 333px;
    right: -175px;
    top: -183px;
}
.partners__title h2 {
    font-family: Poppins;
    font-weight: 700;
    font-size: 60px;
    line-height: 1;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;
}
.partners__text {
    margin-top: 137px;
    font-family: Poppins;
    font-style: italic;
    font-weight: 275;
    font-size: 40px;
    line-height: 90px;
    text-align: center;
}
#team {
    padding-top: 182px;
}
.team__wrapper {
    max-width: 1300px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.team__title h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 45px;
    text-transform: uppercase;
}
.team__list {
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.team__item {
    position: relative;
    border-radius: 30px;
    max-width: 396px;
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 24px 20px;
    box-sizing: border-box;
}
.team__item::before {
    border-radius: 30px;
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    padding: 2px;
    background:linear-gradient(200deg,#1D0AE1,#F7061C); 
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events:none
}

.team__infobox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.team__image {
    max-width: 213px;
    margin-bottom: 53px;
}
.team__name > span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 16px;
    display: block;
    text-transform: uppercase;
}
.team__description > p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.253235px;
}
.team__text {
    position: relative;
    border-radius: 30px;
    width: 100%;
    padding: 35px 57px;
    box-sizing: border-box;
    display: flex;
    margin-top: 40px;
    margin-bottom: 45px;
    align-items: center;
}
.team__text::before {
    border-radius: 30px;
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    padding: 2px;
    background:linear-gradient(182deg,#1D0AE1,#F7061C); 
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events:none
}
.team__text h3 {
    /* font-family: 'Druk Wide Web Bold';
    font-size: 20px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 1.2;
    min-width: 308px;
    display: inline-block;
}
.team__text p {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.253235px;
    padding-left: 58px;
}
.team__socials {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}
.team__socials a {
    display: flex;
    justify-content: center;
    align-items: center;
}
.team__socials a:first-of-type {
    margin-right: 20px;
}
.team__cta {
    max-width: 521px;
}
.team__cta a {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 46px 70px;
    flex-direction: column;
    -webkit-flex-direction: column;
}
.team__cta a > span {
    display: block;
}
.team__cta a > span:first-of-type {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.107433px;
    margin-bottom: 26px;
    text-transform: uppercase;

}
.team .blur-circle.light-blue {
    width: 333px;
    height: 333px;
    right: 20%;
    top: 0;
}
.roadmap {
    margin-top: 69px;
}
.roadmap__wrapper {
    max-width: 1300px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.roadmap__title h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 45px;
    text-transform: uppercase;
}
.roadmap__list {
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.roadmap__item {
    position: relative;
    border-radius: 30px;
    max-width: 396px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 35px 45px;
    box-sizing: border-box;
}
.roadmap__item::before {
    border-radius: 30px;
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    padding: 2px;
    background:linear-gradient(200deg,#1D0AE1,#F7061C); 
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events:none
}
.roadmap__year {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 39px;
    letter-spacing: 0.253235px;
    color: #D463EC;

}
.roadmap__month {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #FFFFFF;
    text-transform: uppercase;
    margin-bottom: 28px;
}
.roadmap__description ul {
    padding: 0;
    list-style: none;
    margin: 0;
}
.roadmap__description ul li {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.253235px;
    margin-bottom: 22px;
}
.roadmap__text {
    position: relative;
    border-radius: 30px;
    width: 100%;
    padding: 35px 57px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 45px;
}
.roadmap__text::before {
    border-radius: 30px;
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    padding: 2px;
    background:linear-gradient(182deg,#1D0AE1,#F7061C); 
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events:none
}
.roadmap__text h3{
    /* font-family: 'Druk Wide Web Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 35px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 35px;
    margin-bottom: 22px;
}
.roadmap__text p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.253235px;
}

.faq2 {
    margin-top: 118px;
    margin-bottom: 120px;
}
.faq2__wrapper {
    max-width: 1300px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.faq2__title h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 35px;
    margin-bottom: 45px;
}
.faq2__accordion {
    max-width: 926px;
    width: 100%;
    margin: 61px auto 0;
}
.accordion_title {
    cursor: pointer;
    position: relative;
    padding-left: 50px;
}
.accordion_title::before {
    content: '';
    width: 23px;
    height: 18px;
    background-image: url('../../assets/images/dropdown.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
    transition: transform 0.4s ease-in-out 0s;
}
.accordion__item.active .accordion_title::before {
    transform: translateY(-50%) rotate(-90deg);
    transition: transform 0.4s ease-in-out 0s;
}
.accordion_title h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 45px;
    padding: 20px 0;
}
.accordion__item.active .accordion__content {
    height: 100%;
    visibility: visible;
    /* opacity: 1; */
    transition: height 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s;
}
.accordion__content {
    /* height: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.4s ease-in-out 0s, height 0.4s ease-in-out 0s,  ; */
    display: none;
}

.accordion__content.active {
    display: block;
}

.accordion__content p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    padding-left: 55px;
    margin-bottom: 30px;
}

.features__wrapper {
    width: 95%;
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
}

@media only screen and (max-width: 1440px)  {
    .header__wrapper {
        max-width: 1440px;
        width: 100%;
    }
}

@media all and (max-width: 1300px) {
    .team__text {
        margin-top: 20px;
    }
}

@media all and (max-width: 1024px) {
    .header__wrapper {
        padding-top: 100px;
    }

    .header__image-1,
    .header__image-2 {
        width: 35%;
    }

    .header__title h1,
    .header__title .before-span, .header__title .after-span,
    .header__title .before-span-2, .header__title .after-span-2,
    .header__title .before-span-3, .header__title .after-span-3 {
        font-size: 55px;
        line-height: 1;
    }

    .header__video {
        width: 50%;
    }

    .header__video img + img {
        width: 60px;
    }

    .faq__title,
    .introduction__title {
        font-size: 45px;
        line-height: 1.2;
    }

    .faq__bottom-content {
        margin-top: 60px;
    }

    .faq__list ul li,
    .introduction__list ul li {
        font-size: 22px;
        line-height: 1.5;
    }

    .faq__list ul li + li,
    .introduction__list ul li + li {
        margin-top: 30px;
    }

    .investors,
    .supporters,
    .partners {
        margin-top: 120px;
        margin-bottom: 80px;
    }

    .investors__title h2,
    .supporters__title h2,
    .partners__title h2 {
        font-size: 50px;
        margin-bottom: 20px;
    }

    .investors__text,
    .supporters__text,
    .partners__text {
        margin-top: 80px;
        font-size: 35px;
        line-height: 1.8;
    }

    #team {
        padding-top: 150px;
    }

    .team__title h2 {
        margin-bottom: 25px;
    }
    
    .team__name > span {
        font-size: 30px;
        line-height: 1;
    }

    .team__description > p,
    .team__text p {
        font-size: 16px;
        line-height: 1.5;
    }

    .team__text p {
        padding-left: 30px;
    }

    .team__cta a {
        padding: 35px 70px;
    }
    .team__cta a > span:first-of-type {
        font-size: 30px;
        line-height: 1;
        margin-bottom: 15px;
    }

    .roadmap__item {
        padding: 25px 35px;
    }

    .roadmap__year {
        font-size: 18px;
        line-height: 1.5;
    }

    .roadmap__month {
        margin-bottom: 20px;
    }

    .roadmap__description ul li {
        margin-bottom: 20px;
        line-height: 1.2;
    }

    .roadmap__title h2 {
        margin-bottom: 25px;
    }

    .roadmap__list {
        margin-top: 70px;
    }

    .faq2 {
        margin-top: 80px;
        margin-bottom: 120px;
    }

    .faq2__title h2 {
        font-size: 50px;
        line-height: 1;
        margin-bottom: 25px;
    }

    .footer {
        padding: 80px 0 40px 0;
    }

    .footer__image {
        width: 50%;
    }
}

@media all and (max-width: 1023px) {
    .header__wrapper {
        padding-top: 100px;
    }

    .header__title h1,
    .header__title .before-span, .header__title .after-span,
    .header__title .before-span-2, .header__title .after-span-2,
    .header__title .before-span-3, .header__title .after-span-3  {
        font-size: 40px;
        line-height: 1.2;
    }

    .header__video {
        width: 50%;
    }

    .header__video img + img {
        width: 15%;
    }

    .header__top-content {
        position: relative;
        margin-bottom: 20px;
    }

    .header__image-1,
    .header__image-2 {
        width: 40%;
        display: none;
    }

    .header__bottom-content {
        text-align: center;
    }
    
    .header__bottom-content > span {
        font-size: 40px;
        line-height: 1.5;
    }

    .header__bottom-content > p {
        font-size: 22px;
        line-height: 1.5;
    }

    .header__icon-list {
        margin-top: 40px;
    }

    .header__icon-list > div:first-of-type {
        margin-bottom: 60px;
    }

    .features__wrapper {
        width: 90%;
    }

    .icon-list__row {
        justify-content: space-around;
    }

    .icon-list__item {
        width: 30%;
    }

    .photo-content__item {
        flex-direction: column-reverse;
    }

    .photo-content__content {
        width: 100%;
        max-width: none;
        text-align: center;
    }

    .photo-content__content a,
    .photo-content__content button {
        margin: 0 auto;
    }

    .photo-content__title {
        font-size: 35px;
        line-height: 1.5;
    }
    
    .photo-content__image {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
    }

    .photo-content__image img {
        width: 80%;
        margin: 0 auto;
    }

    .photo-content__wrapper {
        width: 90%;
    }

    .photo-content__title {
        text-align: center;
    }

    .faq__wrapper,
    .introduction__wrapper,
    .team__wrapper,
    .roadmap__wrapper,
    .faq2__wrapper {
        width: 90%;
    }

    .faq__title,
    .introduction__title {
        font-size: 40px;
        line-height: 1.5;
    }

    .faq__image,
    .introduction__image {
        width: 80%;
        text-align: center;
        margin: 0 auto 40px;
    }

    .faq__image img,
    .introduction__image img {
        width: 100%;
    }

    .faq__list,
    .introduction__list {
        width: 100%;
        max-width: none;
    }

    .team__wrapper {
        flex-wrap: nowrap;
    }

    .team__list {
        flex-direction: column;
        margin-top: 70px;
    }

    .team__item {
        width: 100%;
        max-width: none;
        margin-bottom: 60px;
    }

    .team__text {
        flex-direction: column;
        text-align: center;
        padding: 35px 5%;
    }

    .team__text h3 {
        margin-bottom: 25px;
        font-size: 30px;
        line-height: 1.2;
    }

    .team__text > p {
        padding-left: 0;
    }

    .roadmap__list {
        flex-direction: column;
    }

    .roadmap__year {
        margin-bottom: 10px;
    }

    .roadmap__month {
        margin-bottom: 30px;
    }

    .roadmap__item {
        max-width: none;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
    }

    .roadmap__description ul {
        column-count: 2;
        -webkit-column-count: 2;
    }

    .roadmap__text {
        margin-top: 0;
        text-align: center;
    }

    .roadmap__text h3 {
        font-size: 35px;
        line-height: 1;
    }

    .faq2 {
        margin-bottom: 80px;
    }
}

@media all and (max-width: 767px) {
    .header__wrapper {
        padding-top: 80px;
    }

    .header__title {
        margin-top: 35px;
    }

    .header__title h1,
    .header__title .before-span, .header__title .after-span,
    .header__title .before-span-2, .header__title .after-span-2,
    .header__title .before-span-3, .header__title .after-span-3  {
        font-size: 30px;
        line-height: 1.5;
    }

    .header__top-content {
        background-color: rgba(0,0,0,0.3);
        padding: 0 5%;
        position: relative;
    }

    .header__video {
        width: 60%;
    }

    .header__image-1,
    .header__image-2 {
        width: 45%;
        top: 80px;
        position: absolute;
    }

    .header__image-1 {
        left: 0;
    }

    .header__image-2 {
        right: 0;
    }

    .header__links {
        flex-direction: column;
        margin-top: 30px;
    }

    .header__links a,
    .header__links button {
        font-size: 16px; 
        line-height: 1;
        margin-bottom: 15px;
    }

    .header__links button + a {
        margin-bottom: 0;
    }

    #features {
        padding-top: 60px;
    }

    .header__bottom-content > span {
        font-size: 35px;
    }

    .header__bottom-content > p {
        margin-top: 40px;
    }

    .icon-list__item {
        margin-top: 2rem;
        width: 50%;
        padding: 0 2.5%;
    }

    .icon-list__item span {
        font-size: 16px;
    }

    .photo-content__title {
        font-size: 30px;
        line-height: 1.5;
    }
    .photo-content__image .triangle {
      top: 55px;
      left: 35px;
      top: 0;
      left: 0;
    }
    
    .faq__title,
    .introduction__title {
        font-size: 30px;
    }

    .faq__bottom-content {
        margin-top: 50px;
    }

    .faq__image,
    .introduction__image {
        width: 90%;
        margin-bottom: 30px;
    }

    .faq__list ul,
    .introduction__list ul {
        padding-left: 10%;
    }

    .faq__list ul li,
    .introduction__list ul li {
        font-size: 18px;
    }

    .faq__button {
        margin-top: 50px;
    }

    .faq__button a {
        font-size: 24px;
        padding: 30px 20px;
        line-height: 1.3;
    }

    .introduction {
        margin-top: 80px;
    }

    .introduction__image {
        margin-bottom: 0;
    }

    .investors,
    .supporters,
    .partners {
        margin-top: 100px;
        margin-bottom: 60px;
        padding: 0 5%;
    }

    .investors__title h2,
    .supporters__title h2,
    .partners__title h2 {
        font-size: 35px;
        line-height: 1.5;
        margin-bottom: 20px;
    }

    .investors__text,
    .supporters__text,
    .partners__text {
        margin-top: 60px;
        font-size: 28px;
        line-height: 1.5;
    }

    .team__image {
        margin-bottom: 30px;
    }
    
    .team__item {
        margin-bottom: 40px;
    }
    
    .team__cta a {
        padding: 35px 40px;
    }

    .team__cta a > span:first-of-type {
        font-size: 25px;
        line-height: 1;
        margin-bottom: 10px;
    }

    .roadmap__description ul {
        column-count: 1;
        -webkit-column-count: 1;
    }

    .roadmap__text {
        padding: 25px 45px;
    }

    .roadmap__text h3 {
        font-size: 30px;
    }

    .faq2 {
        margin-top: 60px;
        margin-bottom: 0;
    }

    .accordion_title h3 {
        font-size: 25px;
        line-height: 1.5;
        padding: 15px 0;
    }

    .accordion_title {
        padding-left: 40px;
    }
    
    .accordion__content p {
        padding-left: 35px;
        margin-bottom: 20px;
    }
    .photo-content__image .rectangle {
      top: 15px;
      right: 30px;
      width: 30px;
  }
  .photo-content__image .diamond {
      width: 30px;
      left: 90px;
      bottom: 10px;
  }
  .photo-content__image .triangle {
      top: 0;
      left: 0;
  }
  .supporters__circle {
      width: 30px;
  }
  .partners .blur-circle.sky-blue,
  .partners .blur-circle.pink,
  .team .blur-circle.light-blue,
  .blur-circle.dark-blue,
  .photo-content__item .blur-circle.purple.last-item,
  .photo-content__item .blur-circle.red.last-item,
  .photo-content__item .blur-circle.red,
  .photo-content__item .blur-circle.purple,
  .faq__image .blur-circle.red,
  .photo-content__item .blur-circle.light-blue,
  .photo-content__item .blur-circle.grey {
      width: 250px;
      height: 250px;
  }
  .photo-content__item .blur-circle.green {
      right: 0;
  }
  .photo-content__image .first.rectangle {
      width: 30px;
  }
  .photo-content__image .first.diamond {
      width: 30px;
  }
  .photo-content__image .first.triangle {
      width: 30px;
      top: 0;
  }
  .photo-content__image .controller--front {
      width: 80%;
      right: -10px;
      top: 60px;
  }
  .photo-content__image .controller--back {
      width: 80%;
      left: 0;
  }
  .photo-content__image .controller--front {
      width: 80%;
      right: -10px;
      top: 60px;
  }
  .photo-content__image .controller--back {
      width: 80%;
      left: 0;
  }
  .faq__image {
    min-height: 300px;
  }
  .faq__image .controller--front {
    width: 80%;
    left: 0;
  }
  .faq__image .controller--back {
      width: 80%;
      right: -5px;
      top: 85px;
  }
  .faq__image .triangle-img {
      top: 21%;
      right: 0%;
  }
  .faq__image .x-img {
      right: 30%;
  }
  .photo-content__image--fix-height {
    min-height: 300px;
  }
}

@media all and (max-width: 400px) {
    .header__title h1,
    .header__title .before-span, .header__title .after-span,
    .header__title .before-span-2, .header__title .after-span-2,
    .header__title .before-span-3, .header__title .after-span-3  {
        font-size: 25px;
        line-height: 1.5;
    }

    .accordion__content p {
        font-size: 20px;
        line-height: 1.4;
    }
}